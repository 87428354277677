import "../assets/css/notFound.css";

export const NotFound = () => {
  return (
    <div className="error-msg-div">
      <p className="error-title">404 FILE not found.</p>
      <p className="error-msg">urlが間違っています.再度リンクをお確かめください.</p>
    </div>
  );
};
export default NotFound;
