import axios from "axios";

export async function postSlack(channelName, txt) {
  return await axios.request({
    method: 'post',
    baseURL: process.env.REACT_APP_SLACK_WEBHOOK_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data: `payload={
      "channel": "${channelName}",
      "username": "stripe-front-err",
      "text": "${txt}",
      "icon_emoji":":ghost:"
    }`
  });
}