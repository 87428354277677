import React, { useState, useEffect} from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import ReactJsAlert from "reactjs-alert";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// @todo tsxファイルにして、絶対パス指定にする。
import { postSlack } from '../slack';

const SetupForm = ({ customer_id, new_plans, has_error }) => {
  const stripe                                = useStripe();
  const elements                              = useElements();
  const [errorMessage, setErrorMessage]       = useState("");
  const [form_error_msg, setFormErrMsg]       = useState("");
  const [name, setName]                       = useState("");
  const [phone, setPhone]                     = useState("");
  const [address, setAddress]                 = useState("");
  const [email, setEmail]                     = useState("");
  const [complete, setComplete]               = useState(false);
  const [touchable, setTouchable]             = useState(false);
  const [addressComplete, setAddressComplete] = useState(false);
  const [isTapped, setIsTapped]               = useState(false);
  
  
  const navigate = useNavigate();

  useEffect(() => {
    let error_msg = "";
    if (!name) {
      error_msg += "[氏名] ";
    }
    if (!email) {
      error_msg += "[メールアドレス] ";
    }
    if (!(address?.line1 && address?.state && address?.postal_code && address?.country)) {
      error_msg += "[住所] ";
    }
    if (!phone) {
      error_msg += "[電話番号] ";
    }
    if (!complete) {
      error_msg += "[クレジットカード情報] ";
    }
    // addressComplete は bool値
    if (error_msg || addressComplete == false) {
      setTouchable(false);
    } else {
      setTouchable(true);
    }

    if (error_msg) {
      error_msg += "の入力内容に不備があります。";
    }

    setFormErrMsg(error_msg);

  }, [name, phone, email, address, complete, addressComplete]);


  // 送信ボタンがクリックした時に呼ばれる関数
  const handleSubmit = async (event) => {
    setIsTapped(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // CUS_UPDATE_APIを叩き、stripe上で顧客情報の更新と支払い情報との紐付けを行う
    const cusUpdate = async (setupIntent) => {
      const paymentMethodId =
        typeof setupIntent.payment_method === "string"
          ? setupIntent.payment_method
          : setupIntent.payment_method.id;
      if (!paymentMethodId) {
        return;
      }

      const res = await axios
        .post(process.env.REACT_APP_CUS_UPDATE_API, {
          customer_id,
          name,
          email,
          payment_method_id: paymentMethodId,
          has_error,
          phone,
          address,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(err.message);
        });
      return res;
    };

    // START_UNREGISTERED_APIを叩き、stripe上で新規登録するサブスクリプションを作成する
    const startSubscription = async (newPlans) => {
      const res = await axios
        .post(process.env.REACT_APP_START_UNREGISTERED_API, {
          customer_id,
          subs: newPlans
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          setErrorMessage(err.message);
        });
      return res;
    };

    const comfirmSetup = async () => {
      // 支払い情報を保存する
      const confirmSetupRes = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/thanks`,
        },
        redirect: "if_required",
      });
      if (confirmSetupRes.error) {
        await postSlack('#212-オンライン診療_stripe-err', `
          SetupIntentの認証に失敗しました。(3Dセキュアを拒否された可能性が高いです) すぐにお電話にて顧客へご連絡ください。\n
          顧客ID: ${customer_id}\n
          顧客名: ${name}\n
          顧客メールアドレス: ${email}\n
          顧客電話番号: ${phone}\n
          プラン内容: ${new_plans.map(new_plan => { return `${new_plan?.subscription_info?.name}: ${new_plan?.subscription_info?.invoice_detail}`; }).join(", ")}\n
          メンション: <U048SFGEH0B> <@U04CU1GD30R> <@U04L3RNQMT6> <@U04CFTND3UG>
        `);
        // エラーの場合、アラートを表示
        setErrorMessage(confirmSetupRes.error.message);
        return;
      }
      
      // 支払い情報の保存状態によって遷移
      let setupIntent = confirmSetupRes.setupIntent;
      // succeeded or processing以外のステータスの場合は、処理手続き失敗。
      if (
        setupIntent.status !== "succeeded" 
        && setupIntent.status !== "processing"
      ) {
        setErrorMessage("お支払い手続きに失敗しました。お手数ですが再度入力をお願いいたします。");
        return;
      }

      // 処理中の場合、リトライを行う。
      if (setupIntent.status === "processing") {
        let retrieveSetupRes = await stripe.retrieveSetupIntent(
          setupIntent.client_secret
        );
        // クレカ情報保存状態がprocessing以外になるまでretrieveSetupIntentで状態を取得
        while (retrieveSetupRes.setupIntent.status === "processing") {
          retrieveSetupRes = await stripe.retrieveSetupIntent(
            setupIntent.client_secret
          );
        }

        if (retrieveSetupRes.setupIntent.status !== "succeeded") {
          setErrorMessage("お支払い手続きに失敗しました。お手数ですが再度入力をお願いいたします。");
          return;
        }

        setupIntent = retrieveSetupRes.setupIntent;
      }

      // 成功時
      const cusUpdateRes = await cusUpdate(setupIntent);
      if (!cusUpdateRes) {
        setErrorMessage("顧客情報更新に失敗しました。お手数ですが再度入力をお願いいたします。");
        return;
      }

      // 顧客情報を更新に成功
      if (new_plans.length <= 0) {
        // 新規プランがない場合 = 支払い情報のみ変更時はサンクスページに遷移
        navigate("/thanks");
        return;
      }

      // 新規登録するサブスクプランが存在する
      const startSubscriptionRes = await startSubscription(new_plans);
      if (!startSubscriptionRes) {
        setErrorMessage("サブスクリプションの作成に失敗しました。お手数ですが再度入力をお願いいたします。");
        return;
      }

      navigate("/thanks");
      return;
    };

    try {
      comfirmSetup();
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <p className="payment-top-msg">
        <span>お支払い手続き</span>
      </p>

      <form className="credit-input-form">
        {/* errorMessage に値が入ったらアラートを表示 */}
        {errorMessage && (
          <ReactJsAlert
            button="はい"
            type="error"
            status={true}
            title={errorMessage}
            Close={() => {
              window.location.reload();
            }}
          />
        )}
        
        {/* 氏名、住所、電話番号を取得する */}
        <AddressElement
          options={{
            mode: 'billing',
            allowedCountries: ['JP'],
            blockPoBox: true,
            fields: { phone: "always" },
            validation: { phone: { required: 'always' } },
            defaultValues: {
              address: { country: "JP" } 
            },
            display: { name: "full"}
          }}
          onChange={(event) => {
            setAddressComplete(event.complete)
            setName(event.value.name);
            setPhone(event.value.phone);
            setAddress(event.value.address);
          }}
        />

        {/* メアド認証を行う */}
        <LinkAuthenticationElement
          onChange={(event) => {
            if (event.complete) {
              setEmail(event.value.email);   
            } else {
              setEmail("");
            }
          }}
          
        />

        <PaymentElement
          onChange={(event) => {
            setComplete(event.complete);
          }}
        />

        <p>※ CVCとは主にクレジットカードの裏面に記載されるセキュリティコードを意味します。</p>

        
        {/* クレカ情報入力フォームの全項目が埋まっている場合クリック可能 */}

        {
          form_error_msg && (<div className="error-block"><p className="error-block_msg">{form_error_msg}</p></div>)
        }
        
        <button
          className={`submit-button ${isTapped ? "isSubmit" : ""}`}
          disabled={!stripe || !touchable || isTapped}
          onClick={handleSubmit}
        >
          {isTapped ? "送信中" : "送信"}
        </button>
      </form>
    </>
  );
};
export default SetupForm;
