import React from 'react'

export const PurchaseInfoBox = ({ newPlans }) => {
  const today = new Date();
  const dayOfWeekStrJP = ["日", "月", "火", "水", "木", "金", "土"];

  if (newPlans.length > 0) {
    return (
      <div className="purchaseInfo_box">
        <p className="purchaseInfo_date">
          初回支払い日 : {today.getFullYear()}年{today.getMonth() + 1}月
          {today.getDate()}日（{dayOfWeekStrJP[today.getDay()]}）
        </p>
        <p className="purchaseInfo_nextdate">次回以降28日ごとに請求</p>
        <div className="purchaseInfo_plan_box">
          <div className="purchaseInfo_img" />
          <div>
            {newPlans.map(
              (newPlan) =>
                (
                  <div key={newPlan['subscription_id']}>
                    <p className="plan_name">{newPlan.subscription_info.name}</p>
                    <label className="plan_price">
                      {newPlan.subscription_info.invoice_detail}
                    </label>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    );
  }
};
export default PurchaseInfoBox;