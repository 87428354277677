import React from "react";

// @todo tsxファイルにして、絶対パス指定にする。
import "../assets/css/thanks.css";

import pic from "../assets/md-checkmark-circle-outline.png";

const ThanksPage = () => {
  return (
    <>
      <div className="thanks">
        <img className="thanks-img" src={pic} width="80px" alt="thanksページのチェックマーク画像" />
        <p className="thanks-msg">
          クレジットカード情報が正常に保存され、お支払いが完了しました。
        </p>
      </div>
    </>
  );
};
export default ThanksPage;
