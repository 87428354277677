// パラメータを取得する関数
// https://www-creators.com/archives/4463
export function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
  results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function uniq(array) {
  return array.filter(function (elem, index, self) {
    return self.indexOf(elem) === index;
  });
}