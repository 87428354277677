import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// @todo tsxファイルにして、絶対パス指定にする。
import SetupForm from "./SetupForm";
import PurchaseInfoBox from "./PurchaseInfo";

import "../assets/css/paymentForm.css";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const PaymentBox = ({ data }) => {
  const options = {
    // Enable the skeleton loader UI for the optimal loading experience.
    // https://stripe.com/docs/payments/link/save-and-reuse?locale=ja-JP
    loader:"auto",
    clientSecret: data.create_client_secret.client_secret,
    // https://stripe.com/docs/elements/appearance-api
    appearance: {
      theme: "stripe",
      labels: "above",
      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        spacingUnit:'5px',
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        borderRadius: "5px",
        spacingGridRow: "15px",
        spacingTab:"20px"
      },
      rules: {
        ".Tab": {
          border: "1px solid #E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
        },

        ".Tab:hover": {
          color: "var(--colorText)",
        },

        ".Tab:focus": {
          color: "var(--colorText)",
          border: "1px solid hsla(210, 96%, 45%, 50%)",
          shadow: "0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)",
        },

        ".Tab--selected": {
          borderColor: "#E0E6EB",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
        },

        ".Input--invalid": {
          boxShadow:
            "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
        },
      },
    },
  };
  
  return (
    <div className="payment-box">
      {/* 新規追加するサブスクプラン一覧を表示 */}
      <PurchaseInfoBox newPlans={data.new_subplan} />

      <Elements stripe={stripePromise} options={options}>
        <SetupForm
          customer_id={data.create_client_secret.customer_id}
          new_plans={data.new_subplan}
          has_error={data.has_error}
        />
      </Elements>
    </div>
  );
};
export default PaymentBox;
