import React, { useState, useEffect } from "react";
import axios from "axios";

// @todo tsxファイルにして、絶対パス指定にする。
import PaymentBox from './PaymentBox';
import { getParam } from '../util';
import { NotFound } from './NotFound'
import { Loading } from './Loading'

const PaymentForm = () => {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const uid  = getParam("id");
  const from = getParam("from");
  const url = `${process.env.REACT_APP_FETCH_SETUP_INTENTS_API}?id=${uid}&from=${from}`
  useEffect(() => {
    // useEffect自体ではasyncの関数を受け取れないので内部で関数を定義して呼び出す。
    const fetchSetupIntents = async () => {
      // FETCH_SETUP_INTENTS_APIをたたき、resをpropsでPaymentBoxに渡す
      await axios
        .get(url)
        .then((res) => {
          // {
          //    create_client_secret:{
          //      client_secret: クレカ情報保存するための鍵
          //      customer_id: stripe customerID
          //    },
          //    new_subplan:[['subscription_id' => id, 'coupon' => int, 'contract_id' => id, 'subscription_info' => ['name' => "薬名", 'invoice_detail' => "料金"]], ['subscription_id' => id, 'coupon' => int, 'contract_id' => id, 'subscription_info' => ['name' => "薬名", 'invoice_detail' => "料金"]], ...],
          //    has_error:(true or false)
          // }
          setData(res.data);
        })
        // @todo 明示的な例外がほぼ200で飛んでるので、err msgに入ることはほぼないと考えて良い。
        .catch((err) => {
          setErrorMessage(err.message);
        });
    };
    fetchSetupIntents();
  }, [url]);

  if (!data) {
    return <Loading />;
  }

  // 正しくresponseされず、client_secretがないと後続処理を行えないので404とする。
  if (errorMessage || !data?.create_client_secret || uid == null || from == null) {
    return <NotFound />;
  }

  return (
    <>
      <h2 className="payment-header">お支払いページ</h2>
      <PaymentBox data={data} />
    </>
  );
};
export default PaymentForm;