import { BrowserRouter, Routes, Route } from "react-router-dom";

// @todo tsxファイルにして、絶対パス指定にする。
import PaymentForm from "./components/PaymentForm";
import ThanksPage from "./components/ThanksPage";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<PaymentForm />} />
        <Route path={`/thanks/`} element={<ThanksPage />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
